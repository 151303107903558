define("discourse/plugins/discourse-private-topics/discourse/pre-initializers/extend-category-for-private-topics", ["exports", "discourse/models/category", "@ember/object/computed"], function (_exports, _category, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "extend-category-for-private-topics",
    before: "inject-discourse-objects",
    initialize() {
      _category.default.reopen({
        private_topics_enabled: Ember.computed("custom_fields.private_topics_enabled", {
          get(fieldName) {
            return Ember.get(this.custom_fields, fieldName) === "true";
          }
        })
      });
    }
  };
});