define("discourse/plugins/discourse-private-topics/discourse/templates/connectors/category-custom-security/private-topics", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.siteSettings.private_topics_enabled}}
    <section>
      <h3>{{i18n "category.private_topics.title"}}</h3>
    </section>
    <section class="field category_private_topics_enabled">
      <label>
        <Input
          @type="checkbox"
          @checked={{this.args.outletArgs.category.custom_fields.private_topics_enabled}}
        />
        {{i18n "category.private_topics.enabled"}}
      </label>
    </section>
  
    {{#if this.args.outletArgs.category.custom_fields.private_topics_enabled}}
      <section class="field">
        <label>
          {{i18n "category.private_topics.allowed_groups_description"}}
        </label>
        <div class="value">
          <GroupChooser
            @content={{this.allGroups}}
            @value={{groupIds}}
            @labelProperty="name"
            @onChange={{action "setAllowedGroups"}}
          />
        </div>
      </section>
    {{/if}}
  {{/if}}
  
  */
  {
    "id": "Okgi8SpV",
    "block": "[[[41,[30,0,[\"siteSettings\",\"private_topics_enabled\"]],[[[1,\"  \"],[10,\"section\"],[12],[1,\"\\n    \"],[10,\"h3\"],[12],[1,[28,[35,1],[\"category.private_topics.title\"],null]],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"section\"],[14,0,\"field category_private_topics_enabled\"],[12],[1,\"\\n    \"],[10,\"label\"],[12],[1,\"\\n      \"],[8,[39,2],null,[[\"@type\",\"@checked\"],[\"checkbox\",[30,0,[\"args\",\"outletArgs\",\"category\",\"custom_fields\",\"private_topics_enabled\"]]]],null],[1,\"\\n      \"],[1,[28,[35,1],[\"category.private_topics.enabled\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"args\",\"outletArgs\",\"category\",\"custom_fields\",\"private_topics_enabled\"]],[[[1,\"    \"],[10,\"section\"],[14,0,\"field\"],[12],[1,\"\\n      \"],[10,\"label\"],[12],[1,\"\\n        \"],[1,[28,[35,1],[\"category.private_topics.allowed_groups_description\"],null]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"value\"],[12],[1,\"\\n        \"],[8,[39,3],null,[[\"@content\",\"@value\",\"@labelProperty\",\"@onChange\"],[[30,0,[\"allGroups\"]],[30,0,[\"groupIds\"]],\"name\",[28,[37,4],[[30,0],\"setAllowedGroups\"],null]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[]],null],[1,[28,[32,0],[\"[[\\\"The `groupIds` property path was used in the `discourse/plugins/discourse-private-topics/discourse/templates/connectors/category-custom-security/private-topics.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.groupIds}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"i18n\",\"input\",\"group-chooser\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-private-topics/discourse/templates/connectors/category-custom-security/private-topics.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});